import { datadogRum } from '@datadog/browser-rum';
import { UNSAVED_DB_DATA } from 'constants/const';
import type { IDBPDatabase } from 'idb';
import { openDB } from 'idb';

const DATABASE_NAME = 'CADI_DB';
const OBJECT_STORE_NAME = 'CADI_STORE';
const DATABASE_VERSION = 26;

// Trying to initialize indexedDB as soon as possible to avoid errors
window.indexedDB;

/**
 * The following methods (handleDBClosingError, handleUnsavedDBData) are here to
 * the following webkit issue: https://carlsberggbs.atlassian.net/browse/CADI-7969
 */
function handleDBClosingError(objType: string, obj: any) {
  sessionStorage.setItem(UNSAVED_DB_DATA, JSON.stringify({ objType, obj }));
  window.location.reload();
}

function handleUnsavedDBData(db: unknown) {
  const data = sessionStorage.getItem(UNSAVED_DB_DATA);
  if (data) {
    const { objType, obj } = JSON.parse(data);
    saveToDB(objType, obj)
      .catch((error) => {
        datadogRum.addError(error);
      })
      .finally(() => sessionStorage.removeItem(UNSAVED_DB_DATA));
  }
  return db;
}

const saveToDB = (objType: string, obj: any) => {
  return dbPromise.then((db: any) => {
    return db.transaction(OBJECT_STORE_NAME, 'readwrite').objectStore(OBJECT_STORE_NAME).put(obj, objType);
  });
};

/**
 * Initialize the IndexedDB.
 * see https://developers.google.com/web/ilt/pwa/lab-indexeddb
 * for information as to why we use switch w/o breaks for migrations.
 * add do the database version and add a switch case each time you need to
 * change migrations
 */
export const dbPromise: Promise<IDBPDatabase> = openDB<any>(DATABASE_NAME, DATABASE_VERSION, {
  upgrade(db, oldVersion, newVersion, transaction) {
    if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
      db.createObjectStore(OBJECT_STORE_NAME);
    } else {
      transaction.done.then(() => db.clear(OBJECT_STORE_NAME));
    }
  }
});

export const loadStoreData = () => {
  return dbPromise
    .then(async (db) => {
      const data = await db.transaction(OBJECT_STORE_NAME).objectStore(OBJECT_STORE_NAME).getAll();
      const keys = await db.transaction(OBJECT_STORE_NAME).objectStore(OBJECT_STORE_NAME).getAllKeys();
      return keys.reduce((obj: any, key: any, index: number) => ({ ...obj, [key]: data[index] }), {});
    })
    .then(handleUnsavedDBData)
    .catch((error) => {
      datadogRum.addError(error);
    });
};

export const saveOne = (objType: string, obj: any) => {
  return saveToDB(objType, obj).catch(() => {
    handleDBClosingError(objType, obj);
  });
};

export const getAll = (tablespace: string) => {
  return dbPromise
    .then((db) => {
      return db.transaction(OBJECT_STORE_NAME).objectStore(OBJECT_STORE_NAME).get(tablespace);
    })
    .catch((error) => {
      datadogRum.addError(error);
    });
};

export const clear = () =>
  dbPromise
    .then((db) => {
      db.clear(OBJECT_STORE_NAME);
    })
    .catch((error) => {
      datadogRum.addError(error);
    });
