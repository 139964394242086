import type { GenericSelector } from 'constants/const';
import type {
  ActionCatalogBasePayload,
  CampaignProductScenario,
  FavouriteListSelected,
  Promotion,
  RewardEntry
} from 'modules/HybrisOrdering/types';
import type Market from 'utils/schemas/market';

export enum OrderStatus {
  Accepted = 'Accepted',
  Blocked = 'Blocked',
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  InPreparation = 'In Preparation',
  InvalidInput = 'Invalid Input',
  Invoiced = 'Invoiced',
  Received = 'Received',
  Shipped = 'Shipped',
  Processed = 'PROC',
  PartiallyProcessed = 'PAPR',
  CANC = 'CANC',
  ProcessingError = 'PROCESSING_ERROR'
}

export interface QueryParams {
  code: string;
  value: string;
}

export interface SortMode {
  code: string;
  label: string;
  selected: boolean;
}

export interface BaseProductRequest {
  soldTo: string;
  shipTo: string;
  page: number;
  deliveryDate: string;
  employeeId?: string;
  queryParams?: QueryParams[];
  sortMode?: string;
  mro?: MRO;
}
export interface ActionCatalogPayload extends ActionCatalogBasePayload {
  query?: string;
  category?: Category;
}
export interface ActionCampaignCatalogPayload extends ActionCatalogBasePayload {
  promotion: Promotion;
}

export interface ActionFavouriteCatalogPayload extends ActionCatalogBasePayload {
  selectedFavourite: FavouriteListSelected;
}

export interface ActionDynamicFilterCatalogPayload extends ActionCatalogBasePayload {
  selectedDynamicFilter: DynamicFilterItem;
}

export type CatalogActionPayload =
  | ActionCatalogPayload
  | ActionCampaignCatalogPayload
  | ActionFavouriteCatalogPayload
  | ActionDynamicFilterCatalogPayload;

export interface Configurations {
  status: string;
  salesOrg: string;
  shiptos: Array<string>;
  showPrices: boolean;
  hasMRO: boolean;
}

export interface FacetValue {
  code: string;
  label: string;
  selected: boolean;
  count: number;
}
export interface Facet {
  code: string;
  label: string;
  values: FacetValue[];
}

export enum MRO {
  mandatory = 'MANDATORY',
  recommended = 'RECOMMENDED',
  optional = 'OPTIONAL',
  all = 'ALL'
}

export interface MROType {
  code: MRO;
  name: string;
}

export interface Product {
  backInStockDate?: string;
  basePrice?: number;
  employeeId?: string;
  entryNumber?: number;
  firstDeliveryDate?: string;
  fromPrice?: number;
  hasValidAssortment?: boolean;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isFavourite: boolean;
  isSRFavourite: boolean;
  mro?: MRO;
  name: string;
  onPromotion: boolean;
  onTradeQuotation?: boolean;
  preferredQuantity?: number;
  price?: number;
  productDeliveryUnitName: string;
  rejectionReason?: string;
  rewardInBasket?: number;
  sku: string;
  skuFE?: string;
  validFrom?: string;
  campaignProductScenario?: CampaignProductScenario;
}

export interface Catalog {
  status: string;
  totalPages: number;
  totalResults: number;
  currencyIsocode: string;
  productList: Product[];
  facets: Facet[];
  sortModes: SortMode[];
  queryParams: QueryParams[];
  suggestions: [];
}

export enum DeliveryDateType {
  STANDARD = 'STANDARD',
  RED_DAY = 'RED_DAY'
}

export interface DeliveryDate {
  cutoff: string;
  deliveryDate: string;
  type?: DeliveryDateType;
}

export interface DeliveryDateEntry {
  cutoff?: string;
  deliveryDate: string;
}

export interface DeliveryOption {
  code: string;
  cost: string;
  currencyIsoCode: string;
}
export interface DeliveryDates {
  status: string;
  deliveryDates: DeliveryDate[];
  deliveryOptions: DeliveryOption[];
}
export interface CartEntry {
  addFree?: boolean;
  allowFreeProduct?: boolean;
  appliedPromotionId?: string;
  atpConfirmedQuantity?: number;
  atpRequestedQuantity?: number;
  backInStockDate?: string;
  basePrice?: number;
  entryNumber?: number;
  giveAway?: boolean;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isEditable?: boolean;
  isFavourite: boolean;
  isFree?: boolean;
  isSavePending?: boolean;
  isSRFavourite: boolean;
  maxQuantity?: number;
  minQuantity?: number;
  mro?: MRO;
  name: string;
  onPromotion: boolean;
  origin?: string;
  packSize?: number;
  quantity: number;
  rejectionReason?: string;
  rejectionReasonName?: string;
  sku: string;
  skuFE: string;
  totalPrice?: number;
  unit: string;
}

export interface UpdateCartEntry {
  sku: string;
  entryNumber?: number;
  quantity?: number;
  freeQuantity?: number;
}

export interface CartTotals {
  totalCode: TotalsCode;
  totalAmount: number;
}
export interface Cart {
  appliedPromotions?: FiredPromotion[];
  atpCheckStatus?: ATPCheckStatus;
  atpRemovedEntries?: CartEntry[];
  cartId?: string;
  currencyIsocode: string;
  customerId: string;
  entries: CartEntry[];
  firedPromotions?: FiredPromotion[];
  orderStatus?: OrderStatus;
  priceSymbol: string;
  rewardsAvailableTQ?: boolean;
  status: string;
  totalDistinctProducts?: number;
  totalProducts: number;
  totals: CartTotals[];
  userMarket?: Market;
  outOfStockEntries?: CartEntry[];
}
export interface CartResult {
  status: string;
  newPromotions?: string[];
  updatedPromotions?: string[];
  cart: Cart;
}
export interface OrderEntry {
  sku: string;
  quantity: number;
  basePrice: number;
  totalPrice: number;
  isFavourite: boolean;
  onPromotion: boolean;
  entryNumber?: number;
}

export interface RejectionReason {
  code: string;
  name: string;
}

export enum TotalsCode {
  subTotalAmount = 'subTotalAmount',
  promotionAmount = 'promotionAmount',
  deliveryCosts = 'deliveryCosts',
  emptiesCosts = 'emptiesCosts',
  invoiceCharges = 'invoiceCharges',
  taxCosts = 'taxCosts',
  totalAmount = 'totalAmount',
  totalWithoutTax = 'totalWithoutTax',
  otherTax = 'otherTax',
  //sweden
  listPrice = 'listPrice',
  adminFees = 'adminFees',
  contractDiscounts = 'contractDiscounts',
  //finland
  surchargesCost = 'surchargesCost',
  minOrderFee = 'minOrderFee',
  //denmark
  handlingFee = 'handlingFee',
  channelDiscounts = 'channelDiscounts',
  headerDiscounts = 'headerDiscounts',
  freightCosts = 'freightCosts',
  //norway
  halfTaxCosts = 'halfTaxCosts',
  freeGoods = 'freeGoods',
  // loyalty points
  totalLoyaltyPointsCollected = 'totalLoyaltyPointsCollected'
}

export interface Order {
  orderNumber: string;
  deliveryDate: Date;
  poReference: string;
  deliveryAddressUid: string;
  deliveryAddressFormatted: string;
  totalProducts: number;
  totalDistinctProducts: number;
  currencyIsocode: string;
  totals: CartTotals[];
  entries: OrderEntry[];
  soldTo: string;
}
export interface OrderResult {
  status: string;
  order: Order;
}
export enum EditOrderStep {
  Check = 'CHECKCHANGES',
  Confirm = 'CONFIRMCHANGES'
}

export enum ATPCheckStatus {
  Ok = 'OK',
  None = 'NONE',
  Full = 'FULL',
  Partial = 'PARTIAL'
}

export const OrderPriceSymbol = [
  { code: 'SEK', symbol: 'kr' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'EUR', symbol: '€' },
  { code: 'DKK', symbol: 'kr' },
  { code: 'GBP', symbol: '£' }
];

export interface Category {
  code: string;
  name: string;
}

export enum PromotionMode {
  QUALIFY = 'QUALIFY',
  REWARD = 'REWARD'
}

export enum FilterPromotionMode {
  NORMAL = 'NORMAL',
  PENDING = 'PENDING'
}

export interface FiredPromotion {
  id: string;
  name: string;
  maxRewardQuantity: number;
  reward?: RewardEntry[];
  qualifyingProducts?: Array<string>;
}

export interface RewardList {
  rewards: Product[];
  priceSymbol: string;
}

export enum OfflineCartStatus {
  SAVED = 'SAVED',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
  NONE = 'NONE'
}

export interface Sku {
  sku: string;
  unit?: string;
}

export enum UkOrderNature {
  Transfer = 'T',
  Agreed = 'A'
}

export const UkOrderNatureOptions: GenericSelector[] = [
  { code: UkOrderNature.Transfer, name: 'transfer' },
  { code: UkOrderNature.Agreed, name: 'agreed' }
];
export interface ResultProducts {
  status: string;
  totalPages: number;
  totalResults: number;
  currencyIsocode: string;
  entries: Product[];
  facets: Facet[];
  sortModes: SortMode[];
}

export enum DynamicFilterActions {
  OrderedProducts = 'ORDERED_PRODUCTS'
}

export enum DynamicFilterTypes {
  NotOrdered4Months = 'NOT_ORDERED_4_MONTHS'
}

// Selector definition
export interface DynamicFilterItem {
  code: DynamicFilterTypes;
  name: string;
}

// Error interfaces
export interface HybrisError {
  status: string;
  messages: HybrisErrorMessage[];
}

export interface HybrisErrorMessage {
  code: string;
  level: string;
  key: string;
  message: string;
}
