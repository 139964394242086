/**
INITIAL → Nothing has been triggered yet. Default status
PENDING → The cart is being validated right now.
STOCK → There are stock issues. The SR needs to change something (or leave it as is) and review it again.
ERROR → Something went wrong while doing the validation (timeout or other SAP errors)
OK → The validation finished successfully and we can place the order right away.
*/
export enum SAPValidationStatus {
  initial = 'INITIAL',
  pending = 'PENDING',
  stock = 'STOCK',
  error = 'ERROR',
  ok = 'OK'
}

export interface ICartDraftStatus {
  status: string;
  sapValidationStatus: SAPValidationStatus;
  errorDetails: string;
  total: number;
  orderDate: string;
  deliveryDate: string;
  totalDistinctProducts: number;
  placedBy: string;
  cartId: string;
  currencyIsoCode: string;
}

export enum DraftOrderStatus {
  InValidation = 'In validation',
  Offline = 'Offline',
  StockIssue = 'Stock issue',
  Error = 'Error',
  Ready = 'Ready',
  OutOfDate = 'Out of Date'
}
