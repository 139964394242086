import type { MROType, QueryParams, SortMode } from 'modules/HybrisOrdering/types';

export enum FavouriteMode {
  DETAILED = 'DETAILED',
  CURATEDLIST = 'CURATEDLIST'
}

export interface ActionCatalogBasePayload {
  outletId: string;
  pageNumber: number;
  isEdit: boolean;
  setLoading: boolean;
  queryParams?: QueryParams[];
  sortMode?: SortMode;
  mro?: MROType;
}

export enum FavouriteUpdateAction {
  add = 'ADD',
  remove = 'REMOVE',
  set = 'SET',
  clear = 'CLEAR',
  sort = 'SORT'
}

export interface FavouriteItem {
  list: string;
  totalProducts?: number;
  productInList?: boolean;
}

export interface FavouriteItemUpdate {
  sku: string;
  preferredQuantity?: number;
  position?: number;
}

export interface FavouritesList {
  favouriteLists?: FavouriteItem[];
  curatedLists?: FavouriteItem[];
  favouriteSRLists?: FavouriteItem[];
}

export interface FavouriteListSelected {
  name: string;
  isSalesRepFavourite: boolean;
  mode: FavouriteMode;
}
export interface Favourite {
  status: string;
  favouritesCount: number;
}

export enum UpdateFavouriteListActions {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  RENAME = 'RENAME'
}

export type FavouriteContext = 'favouriteSidebar' | 'catalog';
