import { IconName } from 'components/Icon';
import { AppConfigManager } from 'config';
import type { DynamicFilterItem, MROType, RejectionReason } from 'modules/HybrisOrdering/types';
import { DynamicFilterTypes, MRO } from 'modules/HybrisOrdering/types';
import Market from 'utils/schemas/market';
import { NotificationColor } from 'utils/schemas/notification';

export function getCartValidationDuration() {
  const {
    hybrisOrdering: { cartValidationDuration }
  } = AppConfigManager.get();
  return cartValidationDuration;
}

/**
 * Environments that we are checking at the app
 */
const ENVIRONMENTS = {
  PROD: 'production',
  DEV: 'development',
  TEST: 'test',
  STAGING: 'staging'
};

/**
 * Entities names
 *
 * Please try to keep this alphabetically ordered
 */
const ENTITY_NAME = {
  ENTITIES: 'entities',
  CONTACTS: 'contacts',
  CUSTOMERS: 'customers',
  HYBRIS_ADDRESSES: 'hybrisAddresses',
  ACTIONS: 'actions',
  NETWORK: 'network',
  NOTES: 'notes',
  NOTIFICATION: 'notification',
  PRODUCTS: 'products',
  SURVEYS: 'surveys',
  AREA_MANAGEMENT: 'areaManagement',
  MAIN_MENU: 'mainMenu',
  VISIT_TYPES: 'visitTypes',
  OUTLETS: 'outlets',
  VISITS: 'visits',
  USER: 'user',
  HYBRIS_ORDERING: 'hybrisOrdering',
  FREE_BEER_ORDERING: 'freeBeerOrdering',
  HYBRIS_ORDER_HISTORY: 'hybrisOrderHistory',
  HYBRIS_PROMOTIONS: 'hybrisPromotions',
  HYBRIS_PROMOTION_CALENDAR: 'hybrisPromotionCalendar',
  HYBRIS_ORDERING_FAVOURITES: 'hybrisOrderingFavourites',
  TASKS: 'tasks',
  DIGITAL_DRAUGHT_MASTER: 'digitalDraughtMaster',
  EVENT: 'event',
  EVENTS: 'events',
  PROSPECTS: 'prospects',
  OUTLET_INFO_FIELDS: 'outletInfoFields',
  PROSPECT_INFO_FIELDS: 'prospectInfoFields'
};

export const VISIT_TYPE_CLASSIFICATION = {
  NCFT: 'NON_CUSTOMER_FACING_TIME',
  REGULAR: 'REGULAR'
} as const;

const ERROR_MESSAGES: { [errorCode: string]: string } = {
  '400': 'Bad request',
  '401': 'Unauthenticated',
  '403': 'Forbidden',
  '404': 'Not found',
  '409': 'Conflict',
  '500': 'Server error',
  '501': 'Server timeout',
  '503': 'Service unavailable'
};

const ERROR_CODE = {
  BAD_REQUEST: '400',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  CONFLICT: '409',
  SERVER_ERROR: '500',
  SERVER_TIMEOUT: '501',
  SERVICE_UNAVAILABLE: '503'
};

enum NotificationType {
  Default = 'default',
  Offline = 'offline',
  CheckedIn = 'checkedIn',
  Success = 'success',
  Error = 'error',
  Syncing = 'syncing',
  CartValidation = 'cartValidation'
}

const DELETE_REJECTION_REASON: RejectionReason = {
  code: 'YC',
  name: 'Deleted by Sales Rep'
};

const NOTIFICATIONS = {
  OFFLINE: {
    id: NotificationType.Offline,
    message: 'youAreOffline',
    type: NotificationType.Offline,
    sticky: true,
    icon: IconName.Offline,
    color: NotificationColor.GRAY
  },
  CHECKED_IN: {
    id: NotificationType.CheckedIn,
    message: 'currentlyCheckedIn',
    type: NotificationType.CheckedIn,
    sticky: true,
    icon: IconName.ClockFill,
    color: NotificationColor.ACTION
  },
  SYNCING: {
    id: NotificationType.Syncing,
    message: 'appSyncing',
    type: NotificationType.Syncing,
    icon: IconName.Sync,
    color: NotificationColor.ALERT
  },
  CART_VALIDATION: (message: string, color: NotificationColor) => ({
    id: NotificationType.CartValidation,
    message,
    type: NotificationType.CartValidation,
    icon: IconName.ShoppingCart,
    color,
    duration: getCartValidationDuration()
  }),
  SUCCESS: (id: string, message: string) => ({
    id,
    message,
    type: NotificationType.Success,
    icon: IconName.Check,
    color: NotificationColor.SUCCESS
  }),
  ERROR: (id: string, error: string, duration?: number) => ({
    id,
    message: error,
    type: NotificationType.Error,
    color: NotificationColor.ERROR,
    duration
  })
};

enum RecurrenceCode {
  NEVER = 'NEVER',
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_2_WEEKS = 'EVERY_2_WEEKS',
  EVERY_3_WEEKS = 'EVERY_3_WEEKS',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_2_MONTHS = 'EVERY_2_MONTHS',
  EVERY_3_MONTHS = 'EVERY_3_MONTHS'
}

enum EventType {
  PLANNED = 'PLANNED',
  UNPLANNED = 'UNPLANNED'
}

export enum EventTypeCalendar {
  SINGLE = 'SINGLE',
  RECURRING = 'RECURRING',
  RECURRING_INSTANCE = 'RECURRING_INSTANCE'
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

enum OrderHistoryDateType {
  CREATION = 'creation',
  DELIVERY = 'delivery'
}

enum RangeSide {
  CURRENTMIN = 'currentMin',
  CURRENTMAX = 'currentMax'
}

export const PAGE_SIZE = {
  DEFAULT: 10,
  OUTLET_LIST: 25,
  PROSPECT_LIST: 25,
  VISIT_PROPOSAL_OUTLETS: 25,
  FREE_BEER_ORDERING: 20,
  DISTRIBUTORS: 20
};

export interface GenericSelector {
  code: string;
  name: string;
}

export interface RecurrenceType {
  code: RecurrenceCode;
  name: string;
}

const RECURRENCES: RecurrenceType[] = [
  { code: RecurrenceCode.NEVER, name: 'never' },
  { code: RecurrenceCode.EVERY_WEEK, name: 'everyWeek' },
  { code: RecurrenceCode.EVERY_2_WEEKS, name: 'every2Weeks' },
  { code: RecurrenceCode.EVERY_3_WEEKS, name: 'every3Weeks' },
  { code: RecurrenceCode.EVERY_MONTH, name: 'everyMonth' },
  { code: RecurrenceCode.EVERY_2_MONTHS, name: 'every2Months' },
  { code: RecurrenceCode.EVERY_3_MONTHS, name: 'every3Months' }
];

const MRO_TYPE: MROType[] = [
  { code: MRO.all, name: 'viewAll' },
  { code: MRO.mandatory, name: 'mandatory' },
  { code: MRO.optional, name: 'optional' },
  { code: MRO.recommended, name: 'recommended' }
];

export const DYNAMIC_FILTER_TYPE: DynamicFilterItem[] = [
  { code: DynamicFilterTypes.NotOrdered4Months, name: 'notOrdered4Months' }
];

export interface SortDirectionType {
  code: SortDirection;
  name: string;
  key: 'orderDate' | 'deliveryDate';
}

const SORT_DIRECTION: SortDirectionType[] = [
  { code: SortDirection.DESC, name: 'orderedDateDescending', key: 'orderDate' },
  { code: SortDirection.ASC, name: 'orderedDateAscending', key: 'orderDate' },
  { code: SortDirection.DESC, name: 'deliveryDateDescending', key: 'deliveryDate' },
  { code: SortDirection.ASC, name: 'deliveryDateAscending', key: 'deliveryDate' }
];

const ORDER_CHANNEL: GenericSelector[] = [
  { code: 'Z16', name: 'Cadi' },
  { code: 'Z08', name: 'EDI' },
  { code: 'Z05', name: 'Mobile' },
  { code: 'Z09', name: 'WebShop Mobile' },
  { code: 'Z01', name: 'IC Call-inbound' },
  { code: 'Z02', name: 'IC Call-outbound' },
  { code: 'Z10', name: 'CRM Back Office' },
  { code: 'Z03', name: 'IC Customer fax' },
  { code: 'Z04', name: 'IC Customer e-mail' },
  { code: 'Z06', name: 'Web' },
  { code: 'Z07', name: 'HHT' },
  { code: 'Z11', name: 'E-commerce' },
  { code: 'Z12', name: 'EMD Online Orders' },
  { code: 'Z13', name: 'VMI Order' },
  { code: 'Z14', name: 'Order Third Party' },
  { code: 'Z15', name: 'Upselling' }
];

const ORDER_TYPE: GenericSelector[] = [
  { code: 'ZISO', name: 'ZISO-statisticalOrder' },
  { code: 'ZOBD', name: 'ZOBD-beerdriveOrder' },
  { code: 'ZOCF', name: 'ZOCF-consignmentFillUp' },
  { code: 'ZOR', name: 'ZOR-standardOrder' },
  { code: 'ZPOS', name: 'ZPOS-posmOrder' },
  { code: 'ZCR', name: 'ZCR-creditMemoRequest' },
  { code: 'ZOCI', name: 'ZOCI-consignmentIssue' },
  { code: 'ZRCR', name: 'ZRCR-consignmentReturns' },
  { code: 'ZRE', name: 'ZRE-returns' },
  { code: 'ZRQA', name: 'ZRQA-returnsForQuality' },
  { code: 'ZSTO', name: 'ZSTO-statisticalOrderUK' }
];

const onTradeMarkets = [Market.DE, Market.DK, Market.FI, Market.FR, Market.NO, Market.SE, Market.UK];

const offTradeMarkets = [Market.DK_OFF_TRADE, Market.FI_OFF_TRADE, Market.NO_OFF_TRADE, Market.SE_OFF_TRADE];

const allTradeMarkets = [...onTradeMarkets, ...offTradeMarkets];

export interface OrderCombination {
  name: string;
  value: boolean;
}

// ordering
export const ORDER_COMBINATION: OrderCombination[] = [{ name: 'no', value: false }];
export const ORDER_COMBINATION_READONLY: OrderCombination[] = [...ORDER_COMBINATION, { name: 'yes', value: true }];

// session storage
export const OUTLET_LIST_FILTERS = 'OUTLET_LIST_FILTERS';
export const ORDER_HISTORY_FILTERS = 'ORDER_HISTORY_FILTERS';
export const CADI_KEYCLOAK_INIT = 'cadiKeycloakInit';
export const CADI_KEYCLOAK_TOKEN = 'cadiKeycloakToken';
export const CADI_KEYCLOAK__REFRESH_TOKEN = 'cadiKeycloakRefreshToken';
export const CATALOG_PAYLOAD = 'CATALOG_PAYLOAD';
export const ORDERING_FILTERS = 'ORDERING_FILTERS';
export const UNSAVED_DB_DATA = 'UNSAVED_DB_DATA';
export const PROSPECT_LIST_FILTERS = 'PROSPECT_LIST_FILTERS';

const REJECTION_REASON = {
  OUT_OF_STOCK: 'Z4'
};

export const KEYCLOAK_NOT_AUTHENTICATED_MESSAGE = 'Not authenticated';

export const FULLCALENDAR_SWITCH_VIEW_SELECTOR = '.fc-mobileButton-button';

export {
  DELETE_REJECTION_REASON,
  ENTITY_NAME,
  ENVIRONMENTS,
  ERROR_CODE,
  ERROR_MESSAGES,
  EventType,
  MRO_TYPE,
  NOTIFICATIONS,
  NotificationType,
  ORDER_CHANNEL,
  ORDER_TYPE,
  OrderHistoryDateType,
  RECURRENCES,
  REJECTION_REASON,
  RangeSide,
  RecurrenceCode,
  SORT_DIRECTION,
  SortDirection,
  allTradeMarkets,
  offTradeMarkets,
  onTradeMarkets
};
