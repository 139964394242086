import { Button, ButtonColor, ButtonStyle } from '@carlsberggroup/malty.atoms.button';
import { Headline, HeadlineAlign, HeadlineColor, HeadlineStyle } from '@carlsberggroup/malty.atoms.headline';
import { Text, TextAlign, TextColor, TextStyle } from '@carlsberggroup/malty.atoms.text';
import { ReactComponent as BookingImage } from 'assets/images/updated/booked.svg';
import { ReactComponent as DistributorImage } from 'assets/images/updated/distributor.svg';
import { ReactComponent as CartImage } from 'assets/images/updated/empty-cart.svg';
import { ReactComponent as FavouriteImage } from 'assets/images/updated/favourites.svg';
import { ReactComponent as FITImage } from 'assets/images/updated/fit.svg';
import { ReactComponent as PromotionsImage } from 'assets/images/updated/no-promotion.svg';
import { ReactComponent as NoResultsImage } from 'assets/images/updated/no-results.svg';
import { ReactComponent as NotesImage } from 'assets/images/updated/notes.svg';
import { ReactComponent as SurveyListImage } from 'assets/images/updated/survey.svg';
import { ReactComponent as TapImage } from 'assets/images/updated/tap.svg';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styles from './empty.module.scss';

export enum EmptyListTypes {
  AREA_MANAGEMENT = 'areaManagement',
  DIGITAL_DRAFT_MASTER = 'digitalDraftMaster',
  NOTES = 'notes',
  SURVEYS = 'surveys',
  EVENTS_HISTORY = 'eventsHistory',
  EVENTS_HISTORY_SEARCH = 'eventsHistorySearch',
  EVENTS = 'events',
  CART = 'cart',
  PROMOTIONS = 'PROMOTIONS',
  PRODUCT_LIST = 'productList',
  ORDER_HISTORY = 'orderHistory',
  OUTLET_LIST = 'outletList',
  VISIT_PROPOSAL = 'visitProposal',
  FIT = 'FIT',
  CONTACTS = 'contacts',
  TASKS = 'tasks',
  FAVOURITE = 'favourite',
  PROSPECT_LIST = 'prospectList',
  PROSPECT = 'prospect',
  DEFAULT = 'default'
}

export enum EmptyVariants {
  PAGE = 'page',
  SEARCH = 'search'
}

interface CommonEmptyProps {
  className?: string;
  title: string;
  subtitle?: string;
  /**
   * @default EmptyListTypes.DEFAULT
   */
  type?: EmptyListTypes;
  shouldShowRecoverButton?: boolean;
}

export interface EmptyPageProps extends CommonEmptyProps {
  variant?: EmptyVariants.PAGE;
  button?: { text: string; onClick?: () => void };
}

export interface EmptySearchProps extends CommonEmptyProps {
  variant?: EmptyVariants.SEARCH;
  button?: never;
}

export type EmptyProps = EmptyPageProps | EmptySearchProps;

const selectImage = (listType: EmptyListTypes) => {
  switch (listType) {
    case EmptyListTypes.DIGITAL_DRAFT_MASTER:
    case EmptyListTypes.AREA_MANAGEMENT:
    case EmptyListTypes.NOTES:
      return TapImage;
    case EmptyListTypes.TASKS:
      return NotesImage;
    case EmptyListTypes.SURVEYS:
      return SurveyListImage;
    case EmptyListTypes.EVENTS_HISTORY:
    case EmptyListTypes.EVENTS:
      return BookingImage;
    case EmptyListTypes.CART:
      return CartImage;
    case EmptyListTypes.PROMOTIONS:
      return PromotionsImage;
    case EmptyListTypes.ORDER_HISTORY:
      return DistributorImage;
    case EmptyListTypes.FIT:
      return FITImage;
    case EmptyListTypes.CONTACTS:
    case EmptyListTypes.FAVOURITE:
      return FavouriteImage;
    case EmptyListTypes.OUTLET_LIST:
    case EmptyListTypes.VISIT_PROPOSAL:
    case EmptyListTypes.PRODUCT_LIST:
    case EmptyListTypes.EVENTS_HISTORY_SEARCH:
    case EmptyListTypes.DEFAULT:
    default:
      return NoResultsImage;
  }
};

const selectTitle = (variant: EmptyVariants, title: string) => {
  return {
    [EmptyVariants.PAGE]: () => (
      <Headline
        as="h1"
        headlineStyle={HeadlineStyle.Large}
        color={HeadlineColor.DigitalBlack}
        align={HeadlineAlign.Center}
        dataTestId="empty-title"
      >
        {title}
      </Headline>
    ),
    [EmptyVariants.SEARCH]: () => (
      <Text
        textStyle={TextStyle.MediumBold}
        color={TextColor.DigitalBlack}
        align={TextAlign.Center}
        dataQaId="empty-title"
      >
        {title}
      </Text>
    )
  }[variant];
};

const selectSubtitleProps = (variant: EmptyVariants) =>
  ({
    [EmptyVariants.PAGE]: {
      textStyle: TextStyle.MediumDefault
    },
    [EmptyVariants.SEARCH]: {
      textStyle: TextStyle.MediumSmallDefault
    }
  }[variant]);

const Empty = ({
  title,
  type = EmptyListTypes.DEFAULT,
  subtitle,
  button,
  className,
  variant = EmptyVariants.PAGE,
  shouldShowRecoverButton = false
}: EmptyProps) => {
  const { t } = useTranslation();
  const Image = selectImage(type);
  const Title = selectTitle(variant, title);
  const subtitleProps = selectSubtitleProps(variant);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <section className={cx(styles.wrapper, styles[variant], className)} data-testid="empty-wrapper">
      <Image className={styles.image} data-testid="empty-image" />
      <Title />
      {subtitle && (
        <Text {...subtitleProps} align={TextAlign.Center} color={TextColor.DigitalBlack} dataQaId="empty-subtitle">
          {subtitle}
        </Text>
      )}
      {button && variant === EmptyVariants.PAGE && (
        <Button
          {...button}
          color={ButtonColor.ThemePrimary}
          className={styles.button}
          style={ButtonStyle.Primary}
          dataTestId="empty-button"
        />
      )}
      {shouldShowRecoverButton && (
        <Button
          color={ButtonColor.ThemePrimary}
          className={styles.button}
          style={ButtonStyle.Primary}
          dataTestId="recover-button"
          text={t('back')}
          onClick={handleGoBack}
        />
      )}
    </section>
  );
};

export default Empty;
