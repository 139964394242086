import type { EventInput } from '@fullcalendar/react';
import type { Product } from 'modules/HybrisOrdering/types';

export enum CampaignProductScenario {
  QUALIFY = 'QUALIFY',
  REWARD = 'REWARD'
}

export enum PromotionType {
  COMPLEX = 'COMPLEX',
  SIMPLE = 'SIMPLE'
}
export interface PromotionCalendar {
  status: 'OK' | 'FAILED';
  campaignList: Campaign[];
  deliveryDates: {
    cutoff: string;
    deliveryDate: string;
  }[];
}

export interface Campaign {
  id: string;
  name?: string;
  description?: string;
  fromDate: string;
  toDate: string;
  campaignType: PromotionType;
  campaignLink?: string;
  productList?: Product[];
}

export interface CampaignDetailsModel extends Campaign {
  status: 'OK' | 'FAILED';
}

export interface PromotionCalendarEvent extends EventInput {
  description: string;
  extendedProps: {
    campaignLink?: string;
    campaignType: PromotionType;
    productList?: Product[];
    qualifyList?: Product[];
    rewardList?: Product[];
  };
}
